import React from "react";
import Image from 'next/image'
import Link from 'next/link'
import PartnersThrre from '@/components/PartnersThrre'
import MoreBtn from "./comman/MoreBtn";
function Banner({bannerCobntent, bannerAcf}:any) {
  return (
    <> 
    {
      bannerAcf?.bannerImage?.sourceUrl && bannerCobntent && (
      <section className="full_banner">
        <div className="container-fluid">
        <div className="row">
            <div className="col-lg-5">
              <div className="left_banner">
                
                <div className="caption">
                  <div className="" dangerouslySetInnerHTML={{ __html:  bannerCobntent  || '' }}></div>
                  {
                    bannerAcf?.bannerButton?.title && (

                    <Link href={bannerAcf?.bannerButton?.url} className="cmn_btn" data-aos="fade-up" data-aos-delay="50">
                      <span>{bannerAcf?.bannerButton?.title}</span>
                    </Link>
                    )
                  }
                   <div className="slider_main">
                      <PartnersThrre/>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="right_banner">
                  <div className="right_img" data-aos="fade-left" data-aos-delay="100">
                      <Image  src={bannerAcf?.bannerImage?.sourceUrl} alt={bannerAcf?.bannerImage?.altText} width={1007} height={797}   loading="eager"/>
                    {/* <div className="" data-aos="fade-left" data-aos-delay="100">
                    </div> */}
                  </div>
              </div>
            </div>
          </div>
        </div>
        <MoreBtn sectionId="whatwedo"/>
      </section>
      )
    }
    </>
  );
}
export default Banner;
