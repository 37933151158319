import React from 'react';
import Link from 'next/link';

const MoreBtn = ({ sectionId }: { sectionId: string }) => {
  const handleScroll = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="hero_btn">
      <button className="hero_btn_box " onClick={() => handleScroll(sectionId)}>
        <span className="bar_text">More</span>
        <span className="bar_line">
          <span className="bar_line_wrap">
            <span className="bar_line_down"></span>
          </span>
        </span>
      </button>
    </div>
  );
};

export default MoreBtn;
