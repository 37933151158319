import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
function Experience({experience, experiencemenus}:any) {
  return (
    <>  
    {
        experience?.experienceBackgroundImage?.sourceUrl &&  experience?.title && (
        <section className='experince' style={{backgroundImage:`url('${experience?.experienceBackgroundImage?.sourceUrl}')`}}>
            <div className="exp_right theme_black white" data-aos="fade-left" data-aos-delay="100">
                {
                    experience?.subTitle && (
                        <span className="sub_heading" data-aos="fade-left" data-aos-delay="100">{experience?.subTitle}</span>
                    )
                }
                <h2 data-aos="fade" data-aos-delay="100">{experience?.title}</h2>
                {
                    experience?.description && (
                        <p data-aos="fade" data-aos-delay="200">
                            {experience?.description}
                        </p>
                    )
                }
                <div className="exp_menus">
                    <div className="row">
                        {
                            experiencemenus?.menu1Title && experiencemenus?.menu1Links && (
                            <div className="col-xl-3 col-md-3 col-sm-6">
                                <div className="exp_menu" data-aos="fade-left" data-aos-delay="100">
                                    <h3>{experiencemenus?.menu1Title}</h3>
                                    <ul>
                                        {
                                            experiencemenus.menu1Links && experiencemenus.menu1Links.map((link:any,index:any)=>{
                                                return (
                                                    <li key={index}>
                                                        <Link href={`${link?.menuLink?.url}`}>{link?.menuLink?.title}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    
                                    </ul>
                                </div>
                            </div>
                            )
                        }
                        {
                            experiencemenus?.menu2Title && experiencemenus?.menu2Links && (
                            <div className="col-xl-3 col-md-3 col-sm-6">
                                <div className="exp_menu" data-aos="fade-left" data-aos-delay="100">
                                    <h3>{experiencemenus?.menu2Title}</h3>
                                    <ul>
                                        {
                                            experiencemenus.menu2Links && experiencemenus.menu2Links.map((link:any,index:any)=>{
                                                return (
                                                    <li key={index}>
                                                        <Link href={`${link?.menuLink?.url}`}>{link?.menuLink?.title}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    
                                    </ul>
                                </div>
                            </div>
                            )
                        }
                         {
                            experiencemenus?.menu3Title && experiencemenus?.menu3Links && (
                            <div className="col-xl-3 col-md-3 col-sm-6">
                                <div className="exp_menu" data-aos="fade-left" data-aos-delay="100">
                                    <h3>{experiencemenus?.menu3Title}</h3>
                                    <ul>
                                        {
                                            experiencemenus.menu3Links && experiencemenus.menu3Links.map((link:any,index:any)=>{
                                                return (
                                                    <li key={index}>
                                                        <Link href={`${link?.menuLink?.url}`}>{link?.menuLink?.title}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    
                                    </ul>
                                </div>
                            </div>
                            )
                        }
                          {
                            experiencemenus?.menu4Title && experiencemenus?.menu4Links && (
                            <div className="col-xl-3 col-md-3 col-sm-6">
                                <div className="exp_menu" data-aos="fade-left" data-aos-delay="100">
                                    <h3>{experiencemenus?.menu4Title}</h3>
                                    <ul>
                                        {
                                            experiencemenus.menu4Links && experiencemenus.menu4Links.map((link:any,index:any)=>{
                                                return (
                                                    <li key={index}>
                                                        <Link href={`${link?.menuLink?.url}`}>{link?.menuLink?.title}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    
                                    </ul>
                                </div>
                            </div>
                            )
                        }
                       
                    </div>
                </div>
            </div>
        </section>
        )
    }
    </>
    
  )
}

export default Experience