import React from "react";
import Image from 'next/image'
import Link from 'next/link'
import WhatsApp from "./comman/WhatsApp";
function Whatwedo({wwdData}:any) {
  return (
    <>
    {
       wwdData?.services && (
        <section id="whatwedo" className="section whatwedo">
          <div className="container">
            <span className="sub_heading" data-aos="fade-right" data-aos-delay="100">{wwdData?.subTitle}</span>
            <div className="row">
              <div className="col-lg-3">
                <div className="wwd_left">
                  {
                    wwdData?.title && (
                      <h2 data-aos="fade" data-aos-delay="100">{wwdData?.title}</h2>
                    )
                  }
                  {
                    wwdData?.description && (
                    <p data-aos="fade" data-aos-delay="200">
                      {wwdData?.description}
                    </p>
                    )
                  }
                <div className="whatwedo_btns">
                  {
                    wwdData?.whatWeDoButton?.title && (
                  <Link href={wwdData?.whatWeDoButton?.url} className="cmn_btn" data-aos="fade-down" data-aos-delay="100">
                    <span>{wwdData?.whatWeDoButton?.title}</span>
                  </Link>

                    )
                  }
                  <WhatsApp/>
                </div>
                </div>
              </div>
              <div className="col-lg-8 offset-lg-1">
                <div className="">
                  <div className="row gx-5">
                  {
                      wwdData?.services && wwdData?.services.map((service:any,index:any)=>{
                        return(
                          <div key={index} className="col-xl-4 col-md-6" data-aos="fade" data-aos-delay="100">
                            <div className="www_box mTop">
                              <Link href={`/services/${service?.slug}`}>
                                <h3 className="f24 fw-800">{service?.title}</h3>
                              </Link>
                              <div className="www_content" dangerouslySetInnerHTML={{ __html: service?.excerpt || '' }}></div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       )
    }
    </>
  );
}

export default Whatwedo;
