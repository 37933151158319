import React, {useState, useEffect} from 'react'
import { useRef } from 'react';
import Cf7FormWrapper from '@/components/cf7-form-wrapper';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRouter } from 'next/router';
function Form({ handler, isLoading, isSent, hasError, captchaKey }: any) {
    const router = useRouter();
    const initialFormState = {
      'your-name': '',
      'your-number': '',
      'your-email': '',
      'web-domain': '',
    };
  
    const [formState, setFormState] = useState(initialFormState);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [gReCaptchaToken, setGReCaptchaToken] = useState<string | null>(null);
    const formRef = useRef<HTMLFormElement>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [loadCaptcha, setLoadCaptcha] = useState(false);
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setLoadCaptcha(true);
      }, 3000); // Adjust the delay time as needed (in milliseconds)
  
      return () => clearTimeout(timeoutId);
    }, []);
  
    const handleFieldChange = (
      field: string,
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
      setFormState({
        ...formState,
        [field]: e.target.value,
      });
    };
  
    const handleCaptchaChange = (token: string | null) => {
      setGReCaptchaToken(token);
    };
  
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      const isEmpty = Object.values(formState).some((value) => value === '');
      if (isEmpty) {
        setShowEmptyError(true);
        setSubmitSuccess(false);
        return;
      }
  
      setShowEmptyError(false);
      handler(e, formState);
  
      setSubmitSuccess(true);
      await router.push('/thank-you'); 
  
      if (formRef.current) {
        formRef.current.reset();
        setFormState(initialFormState);
        setGReCaptchaToken(null);
  
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
      }
    };
      
  
    return (
        <>
        
            {/* <form  ref={formRef} onSubmit={handleFormSubmit}>
                <div className="input_wraper">
                    <input type="text" placeholder='First Name' name="first-name" onChange={(e) => handleFieldChange("first-name", e)} />
                    {showEmptyError && formState['first-name'] === '' && <p className='error_red'>First name is required.</p>}
                </div>
                <div className="input_wraper">
                    <input type="text" placeholder='Last Name' name="last-name" onChange={(e) => handleFieldChange("last-name", e)} />
                    {showEmptyError && formState['last-name'] === '' && <p className='error_red'>Last name is required.</p>}
                </div>
                <div className="input_wraper">
                    <input type="email" placeholder='Email Address' name="your-email" onChange={(e) => handleFieldChange("your-email", e)}/>
                    {showEmptyError && formState['your-email'] === '' && <p className='error_red'>Email is required.</p>}
                </div>
                <div className="input_wraper">
                    <input type="submit" value='Sign Up' />
                </div>
            </form> */}
            <form  ref={formRef} onSubmit={handleFormSubmit}>
                <div className="input_wraper">
                    <input type="text" placeholder='Name' name="your-name" onChange={(e) => handleFieldChange("your-name", e)} />
                    {showEmptyError && formState['your-name'] === '' && <p className='error_red'>Name is required.</p>}
                </div>
                <div className="input_wraper">
                    <input type="number" placeholder='Phone Number' name="your-number" onChange={(e) => handleFieldChange("your-number", e)} />
                    {showEmptyError && formState['your-number'] === '' && <p className='error_red'>Phone Number is required.</p>}
                </div>
                <div className="input_wraper">
                    <input type="email" placeholder='Email Address' name="your-email" onChange={(e) => handleFieldChange("your-email", e)}/>
                    {showEmptyError && formState['your-email'] === '' && <p className='error_red'>Email is required.</p>}
                </div>
                <div className="input_wraper">
                    <input type="text" placeholder='Web Domain' name="web-domain" onChange={(e) => handleFieldChange("web-domain", e)} />
                    {showEmptyError && formState['web-domain'] === '' && <p className='error_red'>Web Domain is required.</p>}
                </div>
                
                <div className="input_wraper">
                    <input type="submit" value='Sign Up' />
                </div>
            </form>
            {loadCaptcha && (
                    <div className="recaptcha">
                        <ReCAPTCHA size="invisible" ref={recaptchaRef} sitekey={captchaKey} onChange={handleCaptchaChange} />
                    </div>
                )}
            <div className="success_message">
                {isLoading &&  
                <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                }
                {isSent && submitSuccess &&  <div className="alert-success text-success" role="alert"><strong>Thank you for your subscribe!</strong></div>}
            </div>
        </>
    )
  }
function InsightBox({insights}:any) {
  
  return (
    <>
 
    <section className='sp_bottom'>
        <div className="container">
            <div className="row">
                <div className="col-lg-3">
                 <div className="insightbox_left">
                    {
                        insights?.title && (
                            <h2 className='f24 mb-0 ' data-aos="fade" data-aos-delay="100">{insights?.title}</h2>
                        )
                    }
                    {
                        insights?.description && (
                            <p className='f16 mt-1' data-aos="fade" data-aos-delay="200">{insights?.description}</p>
                        )
                    }
                 </div>
                </div>
                <div className="col-lg-8 offset-lg-1">
                    <div className="section_right"> 
                        {/* section_right */}
                        <div className="insight_form" data-aos="fade-up" data-aos-delay="100">

                            <Cf7FormWrapper formId="300">
                                <Form />
                            </Cf7FormWrapper>
                            {
                                insights?.formDescription && (
                                    <p>{insights?.formDescription}</p>
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default InsightBox