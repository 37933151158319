"use client";
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import Image from 'next/image'
import Link from 'next/link'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getRecognitionData  } from '../lib/comman';

interface RecognitionSection {
  subTitle: string;
  title: string;
  description: string;
  recognitionButton: {
    title: string;
    url: string;
  };
}

interface PartenersLogos {
  parteners: {
    partenerLogo: {
      altText: string;
      sourceUrl: string;
    };
  }[];
}

interface RecognitionData {
  recognitionSection: RecognitionSection;
  partenersLogos: PartenersLogos;
}
function Recognition() {

  const [recognitionData, setRecognitionData] = useState<RecognitionData | null>(null);

  
  

    useEffect(() => {
      const fetchData = async () => {
        try {
          const data = await getRecognitionData();
          setRecognitionData(data);
        } catch (error) {
          console.error('Error fetching recognition data:', error);
        }
      };
    
      fetchData();
    }, []);
    const recognitionContent = recognitionData?.recognitionSection;
    const partenersLogos = recognitionData?.partenersLogos;
  

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    // fade: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          // arrows:false,
       
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
    
    {
      partenersLogos?.parteners && (
        <section className="section recognition">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
              {recognitionContent?.subTitle && (

                <span className="sub_heading" data-aos="fade-right" data-aos-delay="100"> {recognitionContent?.subTitle}</span>
              )}
              {recognitionContent?.title && (
                <h2 data-aos="fade" data-aos-delay="100">
                  {recognitionContent?.title}
                </h2>
              )

              }
              {recognitionContent?.description && (

                <p data-aos="fade" data-aos-delay="200">
                {recognitionContent?.description}
                </p>
              )}
              {
                recognitionContent && recognitionContent?.recognitionButton?.url && (
                  <Link href={`${recognitionContent && recognitionContent?.recognitionButton?.url}`} className="cmn_btn" data-aos="fade-down" data-aos-delay="100">
                    <span>{recognitionContent && recognitionContent?.recognitionButton?.title}</span>
                  </Link>
                )
              }
              </div>
              <div className="col-lg-8 offset-lg-1">
                <div className="section_right slider_main">
                  <Slider {...settings}>
                    {
                      partenersLogos?.parteners && partenersLogos?.parteners.map((logo:any,index:any)=>{
                        return(
                          <div key={index} className="item" data-aos="fade-left" data-aos-delay="100">
                              <Image src={logo?.partenerLogo?.sourceUrl} alt={logo?.partenerLogo?.altText}  width={186} height={83} loading="lazy"/>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
    </>
  );
}

export default Recognition;
