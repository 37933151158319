import React from "react";
import Link from 'next/link'
function Faqs({faqs}:any) {
  return (
    <>
    {
       faqs?.faqs && (
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                {
                  faqs?.subTitle && (
                    <span className="sub_heading" data-aos="fade-right" data-aos-delay="100">{faqs?.subTitle}</span>
                  )
                }
                {
                  faqs?.title && (
                    <h2 data-aos="fade" data-aos-delay="100">
                      {faqs?.title}
                    </h2>
                  )
                }
                {
                  faqs?.description && (
                    <p data-aos="fade" data-aos-delay="200">
                      {faqs?.description}
                    </p>
                  )
                }
                {
                  faqs?.faqsButton?.title && (
                  <Link href= {faqs?.faqsButton?.url} className="cmn_btn" data-aos="fade-down" data-aos-delay="100">
                    <span> {faqs?.faqsButton?.title}</span>
                  </Link>
                  )
                }
              </div>
              <div className="col-lg-8 offset-lg-1">
                <div className="section_right" data-aos="fade-left" data-aos-delay="100">
                  <div className="accordion" id="accordionExample">
                      {
                        faqs?.faqs && faqs?.faqs.map((faq: any, index: number) => {
                          const faqId = `faq${index + 1}`; 
                          const isShow = index === 0;
                          return (
                            <div key={index} className="accordion-item">
                              <h2 className="accordion-header">
                                <button className={`accordion-button ${isShow ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#${faqId}`} aria-expanded={isShow ? 'true' : 'false'} aria-controls={faqId}>
                                  {faq?.question}
                                </button>
                              </h2>
                              <div id={faqId} className={`accordion-collapse collapse ${isShow ? 'show' : ''}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                  <p>{faq?.answer}</p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       )
    }
    </>
  );
}

export default Faqs;
