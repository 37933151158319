import React from 'react'
import Link from 'next/link'
function Quote({quote}:any) {
  return (
    <>
  {
   quote?.title && quote?.quoteButton?.title && (

   <section className='sp_bottom quote'>
      <div className="container">
        <h2 data-aos="fade" data-aos-delay="100">{quote?.title}</h2>
        {
          quote?.description && (
            <p className="" data-aos="fade" data-aos-delay="200" dangerouslySetInnerHTML={{ __html: quote?.description || '' }}></p>
          )
        }
          <Link href={quote?.quoteButton?.url} className='cmn_btn' data-aos="fade-down" data-aos-delay="100">
            <span>{quote?.quoteButton?.title}</span>
          </Link>
      </div>
   </section>
    )
  }  
    </>
  )
}

export default Quote