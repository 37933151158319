import React, { useState, useEffect } from 'react';
import Image from 'next/image'
import Link from 'next/link'
import { getStartProjectData  } from '../lib/comman';

interface StartProjectData {
    title:string;
    description:string;
    startProjectButton:{
        title:string;
        url:string;
    },
    agentExtraLogo:{
        sourceUrl:string;
        altText:string;
    }
}

function StartaProject() {

    const [startProjectData, setStartProjectData] = useState<StartProjectData | null>(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getStartProjectData();
            setStartProjectData(data);
          } catch (error) {
            console.error('Error fetching Start Project data:', error);
          }
        };
      
        fetchData();
      }, []);

  return (
    <>
    {
        startProjectData?.title && (
            <section className=''>
                <div className="sp_wrap theme_black">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="sp_content white">
                                    <h2 data-aos="fade" data-aos-delay="100">{startProjectData?.title}</h2>
                                    {
                                        startProjectData?.description && (
                                            <p className='f16' data-aos="fade" data-aos-delay="200">
                                                {startProjectData?.description}
                                            </p>
                                        )
                                    }
                                    {
                                        startProjectData?.startProjectButton?.url && (
                                            <Link href={`${startProjectData?.startProjectButton?.url}`} className='cmn_btn_fill' data-aos="fade-down" data-aos-delay="100">
                                                {startProjectData?.startProjectButton?.title}
                                            </Link>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="shape_bg">
                                    <Image 
                                    src={startProjectData?.agentExtraLogo?.sourceUrl || '/images/a.png'}
                                    alt={startProjectData?.agentExtraLogo?.altText || 'agent extra'} width={265} height={237} data-aos="fade-down-left" data-aos-delay="100" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
    </>
  )
}

export default StartaProject