
"use client"
import Banner from '@/components/Banner'
import Whatwedo from '@/components/Whatwedo'
import Brand from '@/components/Brand'
import Recognition from '@/components/Recognition'
import Quote from '@/components/Quote'
import Experience from '@/components/Experience'
import Faqs from '@/components/Faqs'
import InsightBox from '@/components/InsightBox'
import WdInsight from '@/components/WdInsight'
import StartaProject from '@/components/StartaProject'
import { getRecentPosts  } from '../lib/post';
import { getBrandLogos  } from '../lib/comman';
import { getHomePageData  } from '../lib/home';
import { GetStaticProps } from 'next'
import { getSeoData  } from '../lib/seo';
import SEO from '@/components/SEO'
export default function Home({recentPosts, brandLogos, initialHomePageData, initialSeo }:any) {

  const bannerContent = initialHomePageData?.content;
  const banneracf =  initialHomePageData?.home?.bannerSection
  const wwdData = initialHomePageData?.home?.whatWeDo
  const brand = initialHomePageData?.home?.brand
  const quote = initialHomePageData?.home?.quoteSection
  const experience = initialHomePageData?.home?.experience
  const experiencemenus = initialHomePageData?.home?.experience?.menus
  const faqs = initialHomePageData?.home?.faqs
  const insights = initialHomePageData?.home?.insights
  return (
    
   <div className='home_page'>
        <SEO initialSeo={initialSeo}/>
        <Banner bannerCobntent={bannerContent} bannerAcf={banneracf} />
        <Whatwedo wwdData={wwdData}/>
        <Brand logos={brandLogos} brand={brand}/>
        <Recognition/>
        <Quote quote={quote}/>
        <Experience experience={experience} experiencemenus={experiencemenus}/>
        <Faqs faqs={faqs}/>
        <InsightBox insights={insights}/>
        {/* <WdInsight data={recentPosts}/> */}
        <StartaProject/>
    
   </div>
   
  
  )
}


export const getStaticProps: GetStaticProps = async () => {
  const recentPosts = await getRecentPosts()
  const brandLogos = await getBrandLogos()
  const initialHomePageData = await getHomePageData()
  const initialSeo = await getSeoData("home")
  return {
    props: { recentPosts, brandLogos, initialHomePageData, initialSeo },
    revalidate: 60,
  }
}