import React from "react";
import Image from 'next/image'
function Brand({logos, brand}:any) {
  
  const firstSixLogos = logos.brandLogos.slice(0, 6); 
  return (
    <>
    {
      brand.brandBackgroundImage?.sourceUrl && firstSixLogos && (
      <section className="brand" style={{backgroundImage:`url('${brand.brandBackgroundImage?.sourceUrl}')`}}>
        <div className="brend_left theme_gradiunt white">
          {
             brand?.subTitle && (
               <span className="sub_heading" data-aos="fade-right" data-aos-delay="100">{brand?.subTitle}</span>
             )
          }
          {
            brand?.title && (
              <h2 data-aos="fade" data-aos-delay="100">{brand?.title}</h2>
            )
          }
          {
            brand?.description && (
            <p data-aos="fade" data-aos-delay="200">
              {brand?.description}
            </p>
            )
          }
          <div className="brand_logos">
              {
                firstSixLogos && firstSixLogos.map((logo:any,index:any)=>{
                  return(
                    <div key={index} className="brand_logo" data-aos="fade-right" data-aos-delay="100">
                      <Image src={logo?.brandLogo?.sourceUrl} alt={logo?.brandLogo?.altText} width={252} height={57}/>
                    </div>
                  )
                })
              }
          </div>
        </div>
      </section>
      )
    }
    </>
  );
}

export default Brand;
